import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";
import { mobile } from "clutch/src/Style/style.mjs";

import { readState } from "@/__main__/app-state.mjs";
import ChampionImg from "@/game-lol/components/ChampionImg.jsx";
import {
  ROLE_INDEX,
  ROLE_SYMBOL_TO_STR,
  ROLE_SYMBOLS,
} from "@/game-lol/constants/constants.mjs";
import lolRefs from "@/game-lol/refs.mjs";
import getRoleIcon from "@/game-lol/utils/get-role-icon.mjs";
import RoleSymbol from "@/game-lol/utils/symbol-role.mjs";
import {
  getDefaultedFiltersForChampions,
  getSearchParamsForChampions,
  getStaticData,
} from "@/game-lol/utils/util.mjs";
import getTierIcon from "@/shared/get-tier-icon-path.mjs";
import { getLocaleString } from "@/util/i18n-helper.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const percentOptions = {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
  style: "percent",
};

const commonColumnCss = `
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: var(--shade1);
  font-size: var(--sp-3_5);
`;

export const cssRank = () => css`
  color: var(--shade0);
`;
export const cssChampionName = () => css`
  ${commonColumnCss};
  justify-content: flex-start;
  color: var(--shade0);
  padding-inline: var(--sp-2);

  .championKey {
    display: flex;
    align-items: center;
    gap: var(--sp-4);

    ${mobile} {
      gap: var(--sp-3);
    }
  }

  span {
    color: var(--shade0);
  }
`;

const useChampionsPatchChange = (patches, filterRole) => {
  const { t } = useTranslation();
  const {
    lol: { championsStats },
  } = useSnapshot(readState);

  const searchParams = new URLSearchParams();
  const filters = getDefaultedFiltersForChampions(searchParams, "overview");
  const patchUrlParams = getSearchParamsForChampions(false, filters);
  const currentPatchStats = championsStats?.[patchUrlParams.toString()];

  const filterRoleSymbol = RoleSymbol(filterRole);

  const champions = getStaticData("champions");

  const currentPatch = currentPatchStats?.[0]?.patch;
  const previousPatch = currentPatchStats?.[0]?.previous_patch_stats?.patch;

  const rows = useMemo(() => {
    if (!currentPatchStats || currentPatchStats instanceof Error) return null;

    const urlSearchParams = new URLSearchParams();

    const stats = [...currentPatchStats]
      .filter((c) => {
        const isCommon = c.champion_role_pickrate >= 0.01;
        const hasRole = c.individual_position !== ROLE_SYMBOLS.all;
        return isCommon && hasRole;
      })
      .map((champStats) => {
        const {
          champion_id: championId,
          individual_position,
          champion_role_tier: tierListTier,
          win_rate: wr,
          previous_patch_stats = {},
        } = champStats;

        const championKey = champions?.keys?.[championId];
        const champion = champions?.[championKey];

        if (!champion || !championKey) return null;

        if (!previous_patch_stats) return null;

        const linkParams = new URLSearchParams(urlSearchParams);
        if (individual_position && individual_position !== ROLE_SYMBOLS.all) {
          linkParams.set("role", ROLE_SYMBOL_TO_STR[individual_position]?.gql);
        }

        const championName = champion.name;
        const RoleIcon =
          individual_position && getRoleIcon(individual_position);

        const tier = tierListTier;
        const TierIcon = getTierIcon(tier);

        const wrPrev = previous_patch_stats.win_rate;

        // keep any increase in winrate no matter how small,
        // just to ensure we have decent results
        if (wr < wrPrev) return null;

        return [
          {
            display: RoleIcon ? (
              <RoleIcon style={{ height: 24, width: 24 }} />
            ) : null,
            value: individual_position,
          },
          {
            display: (
              <>
                <ChampionImg id={championId} size={28} />
                <span className="champion-name">{championName}</span>
              </>
            ),
            value: championName,
            className: "champion-meta",
            link: `/${lolRefs.lolChampionPrefix}/${championKey}/build?${linkParams}`,
          },
          {
            display: <img src={TierIcon} width="28" height="28" />,
            value: tier * -1,
          },
          {
            display: getLocaleString(wr, percentOptions),
            value: wr,
          },
          {
            display: getLocaleString(wr - wrPrev, percentOptions),
            value: wr - wrPrev,
          },
        ];
      })
      .filter(Boolean)
      .reduce((acc, curr) => {
        // Keep track of biggest change per role, per tier
        const role = curr[0].value;
        const change = curr[4].value;

        if (!acc[role] || change > acc[role].value) {
          acc[role] = curr;
          acc[role].value = change;
        }

        return acc;
      }, {});

    return [
      ROLE_SYMBOLS.top,
      ROLE_SYMBOLS.jungle,
      ROLE_SYMBOLS.mid,
      ROLE_SYMBOLS.adc,
      ROLE_SYMBOLS.support,
    ].map((role) => {
      // Prefer higher tiers
      const val = stats?.[role];
      return val;
    });
  }, [currentPatchStats, champions]);

  const filteredData = useMemo(() => {
    if (!rows) return null;
    return rows
      .filter((v) => {
        if (!filterRoleSymbol) return true;
        const champRoleSymbol = v[0].value;
        const isRole = champRoleSymbol === filterRoleSymbol;
        return isRole;
      })
      .sort((a, b) => {
        return ROLE_INDEX[a[0].value] - ROLE_INDEX[b[0].value];
      });
  }, [rows, filterRoleSymbol]);

  return {
    cols: [
      { display: t("lol:role", "Role"), align: "center" },
      {
        display:
          currentPatch && previousPatch
            ? `${previousPatch} → ${currentPatch}`
            : t("lol:champion", "Champion"),
        align: "left",
        primary: true,
      },
      { display: t("common:tier", "Tier"), align: "center" },
      {
        display: t("common:winRate", "Win Rate"),
        align: "right",
        isStat: true,
      },
      {
        display: t("common:change", "Change"),
        align: "right",
        isStat: true,
      },
      // {
      //   display: t("common:banRate", "Ban Rate"),
      //   align: "right",
      //   isStat: true,
      // },
      // {
      //   display: t("common:pickRate", "Pick Rate"),
      //   align: "right",
      //   isStat: true,
      // },
      // { display: t("common:matches", "Matches"), align: "right", isStat: true },
    ],
    rows: filteredData,
  };
};

export default useChampionsPatchChange;
